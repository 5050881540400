.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    font-size: 14px;
    height: 100%;
}
.ads_container {
    composes: container;
    height: calc(100% - 120px);
    padding: 0;
}
@media only screen and (max-width: 768px) {
    .ads_container {
        height: auto;
        margin-top: 5px;
    }
}
.inner {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
@media only screen and (max-width: 768px) {
    .inner {
        max-width: 100%;
    }
    .ads_container .inner {
        gap: 5px;
    }
}
.gif {
    display: block;
    max-width: 100%;
    height: auto;
}

.sticker {
    composes: gif;
    background-color: var(--giphy-black);
    background-image: linear-gradient(45deg, #161616 25%, transparent 25%, transparent 75%, #161616 75%, #161616),
        linear-gradient(45deg, #161616 25%, transparent 25%, transparent 75%, #161616 75%, #161616);
    background-position:
        0 0,
        30px 30px;
    background-size: 60px 60px;
}

.geocontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    width: 800px;
    padding-bottom: 40px;
    border: var(--giphy-dark-grey) 1px solid;
    border-radius: 5px;
}
@media only screen and (max-width: 768px) {
    .geocontainer {
        width: auto;
    }
}

.geotext {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column
}

.geotext span {
    color: var(--giphy-white);
    background-color: var(--giphy-purple);
    background-image: linear-gradient(to top right, var(--giphy-purple), var(--giphy-indigo));
    font-size: 22px;
    font-weight: bold;
    padding: 3px 15px;
    margin-bottom: 6px;
    border-radius: 6px;
}