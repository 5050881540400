/* leaderboard */

.top_leaderboard_container {
    position: sticky;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: var(--giphy-darkest-grey);
    height: 120px;
}
.top_leaderboard_placeholder {
    background-color: var(--giphy-dark-grey);
    border-radius: 4px;
    overflow: hidden;
    width: 720px;
    height: 90px;
}

@media only screen and (max-width: 768px) {
    .top_leaderboard_container {
        height: 60px;
    }
    .top_leaderboard_placeholder {
        width: 320px;
        height: 50px;
    }
}

/* skyscraper (desktop only) */
.skyscraper_container {
    border-radius: 4px 0 0 4px;
    overflow: hidden;
    position: fixed;
    right: 0;
}

@media only screen and (max-width: 768px) {
    .skyscraper_container {
        display: none;
    }
}

/* medium_rectangble (mobile only) */
@media only screen and (max-width: 768px) {
    .medium_rectangle_container {
        height: 250px;
        width: 100%;
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0;
        margin-bottom: 5px;
    }
}
