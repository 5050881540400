.container {
    color: var(--giphy-white);
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 600;
    height: 30px;
    line-height: 32px;
    text-decoration: none;
    animation: attribution-in 0.3s ease-out;
    text-wrap: nowrap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.avatar {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 30px;
    width: 30px;
}

.logo {
    display: inline-block;
    height: 20px;
    margin: 0 5px 0 10px;
}

@keyframes attribution-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}