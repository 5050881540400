
.link {
    height: 30px;
    text-align: right;
    animation: more-in 0.3s ease-out;
    color: var(--giphy-blue);
    font-weight: 600;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-wrap: nowrap;
}

.tag {
    text-transform: capitalize;
}

.chevronRight {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 2px;
    border: 2px solid transparent;
    border-radius: 100px;
}
.chevronRight::after {
    content: '';
    display: block; 
    box-sizing: border-box;
    position: absolute;
    width: 8px;
    height: 8px;
    border-bottom: 3px solid;
    border-right: 3px solid;
    transform: rotate(-45deg);
    right: 6px;
    top: 4px;
}

@keyframes more-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
